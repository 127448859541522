@font-face { 
  font-family: 'Noto Sans KR'; 
  font-style: normal;
  font-weight: 100; 
  src: url('./NotoSans-Thin.woff2') format('woff2'), 
    url('./NotoSans-Thin.woff') format('woff'), 
    url('./NotoSans-Thin.otf') format('opentype');
  font-display: swap;
  unicode-range: U+AC00-U+D7A3, U+0030-0039;
}
@font-face { 
  font-family: 'Noto Sans KR'; 
  font-style: normal;
  font-weight: 300;
  src: url('./NotoSans-Light.woff2') format('woff2'),
    url('./NotoSans-Light.woff') format('woff'),
    url('./NotoSans-Light.otf') format('opentype');
  font-display: swap;
  unicode-range: U+AC00-U+D7A3, U+0030-0039;
}
@font-face { 
  font-family: 'Noto Sans KR'; 
  font-style: normal;
  font-weight: 400; 
  src: url('./NotoSans-Regular.woff2') format('woff2'),
    url('./NotoSans-Regular.woff') format('woff'),
    url('./NotoSans-Regular.otf') format('opentype');
  font-display: swap;
  unicode-range: U+AC00-U+D7A3, U+0030-0039;
}
@font-face { 
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500; 
  src: url('./NotoSans-Medium.woff2') format('woff2'), 
    url('./NotoSans-Medium.woff') format('woff'), 
    url('./NotoSans-Medium.otf') format('opentype');
  font-display: swap;
  unicode-range: U+AC00-U+D7A3, U+0030-0039;
}
@font-face { 
  font-family: 'Noto Sans KR'; 
  font-style: normal;
  font-weight: 700; 
  src: url('./NotoSans-Bold.woff2') format('woff2'), 
    url('./NotoSans-Bold.woff') format('woff'), 
    url('./NotoSans-Bold.otf') format('opentype');
  font-display: swap;
  unicode-range: U+AC00-U+D7A3, U+0030-0039;
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('./NotoSans-Black.woff2') format('woff2'),
    url('./NotoSans-Black.woff') format('woff'),
    url('./NotoSans-Black.otf') format('opentype');
  font-display: swap;
  unicode-range: U+AC00-U+D7A3, U+0030-0039;
}
@font-face { 
  font-family: 'Poppins'; 
  font-style: normal; 
  font-weight: 100; 
  src: url('./poppins-v15-latin-100.woff2') format('woff2'),
    url('./poppins-v15-latin-100.woff') format('woff');
  unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face { 
  font-family: 'Poppins'; 
  font-style: normal; 
  font-weight: 200; 
  src: url('./poppins-v15-latin-200.woff2') format('woff2'), 
    url('./poppins-v15-latin-200.woff') format('woff'); 
  unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./poppins-v15-latin-300.woff2') format('woff2'),
    url('./poppins-v15-latin-300.woff') format('woff');
  unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./poppins-v15-latin-regular.woff2') format('woff2'),
    url('./poppins-v15-latin-regular.woff') format('woff');
  unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./poppins-v15-latin-500.woff2') format('woff2'),
    url('./poppins-v15-latin-500.woff') format('woff');
  unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./poppins-v15-latin-600.woff2') format('woff2'),
    url('./poppins-v15-latin-600.woff') format('woff');
  unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./poppins-v15-latin-700.woff2') format('woff2'),
    url('./poppins-v15-latin-700.woff') format('woff');
  unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('./poppins-v15-latin-800.woff2') format('woff2'),
    url('./poppins-v15-latin-800.woff') format('woff');
  unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('./poppins-v15-latin-900.woff2') format('woff2'),
    url('./poppins-v15-latin-900.woff') format('woff');
  unicode-range:U+0041-005A, U+0061-007A, U+0030-0039;
}